const setLocalStorageItem = (key, userInfo) => {
  localStorage.setItem(key, JSON.stringify(userInfo));
};

const getLocalStorageItem = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

const clearLocalStorage = () => {
  localStorage.clear();
};

export { setLocalStorageItem, getLocalStorageItem, clearLocalStorage };
