import client from "./axios";

/**
 * get an user by firstName and password
 * @param {String} email - required
 * @param {String} pwd - required
 * @returns {Object} all information about the user
 */
const getUser = (email, pwd) => {
  return new Promise((resolve, reject) => {
    client
      .get(`/user?email=${email}&pwd=${pwd}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject({ message: "Impossible de joindre le serveur" });
        } else {
          reject({ message: "Erreur inconnue" });
        }
      });
  });
};

/**
 * connect to the server
 * @param {Object} body - required
 * @returns {string} token
 */
const login = (body) => {
  return new Promise((resolve, reject) => {
    client
      .post(`/login`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject({ message: "Impossible de joindre le serveur" });
        } else {
          reject({ message: "Erreur inconnue" });
        }
      });
  });
};

export { getUser, login };
